.balance-carousel {
    height: 100%;
    padding-bottom: 12px;
}

.balance-carousel .swiper-button-prev,
.balance-carousel .swiper-button-next {
    width: 15px;
    height: 30px;
    color: white;
    margin-top: -6px;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
}

.balance-carousel .swiper-button-prev:hover,
.balance-carousel .swiper-button-next:hover {
    background: rgba(255, 255, 255, 0.5);
}

.balance-carousel .swiper-button-prev::after,
.balance-carousel .swiper-button-next::after {
    font-size: 8px;
}

.balance-carousel .swiper-button-prev {
    left: 0;
    padding-right: 5px;
    -webkit-border-radius: 0 50px 50px 0;
    border-radius: 0 50px 50px 0;
}

.balance-carousel .swiper-button-next {
    right: 0;
    padding-left: 5px;
    -webkit-border-radius: 50px 0 0 50px;
    border-radius: 50px 0 0 50px;
}

.balance-carousel .swiper-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
}

.balance-carousel .swiper-pagination-bullet {
    width: 5px;
    height: 5px;
    background: rgba(255, 255, 255, 0.6);
    margin: 0 2px !important;
}

.balance-carousel .swiper-pagination-bullet-active {
    width: 6px;
    height: 6px;
    background: rgba(255, 255, 255, 0.9);
}

.balance-carousel__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 45px;
}

.balance {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    color: white;
    width: 100%;
    height: 112px;
    background: rgba(23, 24, 34, 0.3);
    border-radius: 20px;
}

.balance-title {
    font-size: 14px
}

.balance-value {
    font-weight: 750;
    font-size: 20px;
}

.balance-small {
    font-size: 14px;
    padding-top: 4px;
}
