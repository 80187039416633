:root {
  --swiper-navigation-size: 100px;
}

.hero-carousel {
  height: 100%;
}

.hero-carousel .swiper-button-prev,
.hero-carousel .swiper-button-next {
  width: 50px;
  height: 100px;
  color: white;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
}

.hero-carousel .swiper-button-prev:hover,
.hero-carousel .swiper-button-next:hover {
  background: rgba(255, 255, 255, 0.5);
}

.hero-carousel .swiper-button-prev::after,
.hero-carousel .swiper-button-next::after {
  font-size: 18px;
}

.hero-carousel .swiper-button-prev {
    left: 0;
    padding-right: 8px;
    -webkit-border-radius: 0 50px 50px 0;
    border-radius: 0 50px 50px 0;
}

.hero-carousel .swiper-button-next {
    right: 0;
    padding-left: 8px;
    -webkit-border-radius: 50px 0 0 50px;
    border-radius: 50px 0 0 50px;
}

.hero-carousel .swiper-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 8px;
}

.hero-carousel .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background: rgba(255, 255, 255, 0.6);
}

.hero-carousel .swiper-pagination-bullet-active {
  width: 14px;
  height: 14px;
  background: rgba(255, 255, 255, 0.9);
}

.hero-carousel__image,
.hero-carousel video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px;
    background-color: rgba(40, 41, 55, 0.7) !important;
  }

.home-hero .hero-carousel {
    height: 430px
}


.home-hero .swiper-button-prev,
.home-hero .swiper-button-next {
    margin-top: -49px;
}

.app-hero .hero-carousel__image {
    object-fit: cover;
}

.home-offers .hero-carousel__image {
    border-radius: 20px;
}

.home-offers .swiper-button-prev,
.home-offers .swiper-button-next {
    width: 42px;
    height: 84px;
    margin-top: -42px;
}

@media (max-width: 1366px) {
    .home-hero .hero-carousel {
        height: 370px
    }

    .home-hero .hero-carousel__image {
        border-radius: 30px;
    }

    .hero-carousel .swiper-pagination {
        bottom: 14px;
    }

    .hero-carousel .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
    }

    .hero-carousel .swiper-pagination-bullet-active {
        width: 12px;
        height: 12px;
    }

    .home-offers .hero-carousel__image {
        border-radius: 16px;
    }
}

@media (max-width: 1212px) {
  .hero-carousel {
    padding-bottom: 25px;
  }

    .home-hero .hero-carousel {
        height: auto
    }

    .hero-carousel .swiper-pagination {
        bottom: 13px;
    }

  .hero-carousel .swiper-slide {
    height: auto;
  }

  .home-hero .hero-carousel__image,
  .home-offers .hero-carousel__image {
    border-radius: 20px;
  }

    .home-hero .hero-carousel__image,
    .home-offers .hero-carousel__image{
        border-radius: 20px;
    }

    .hero-carousel .swiper-button-prev,
    .hero-carousel .swiper-button-next {
        display: none;
    }

  .balance-carousel .swiper-pagination {
    bottom: 0;
  }

  .hero-carousel .swiper-pagination-bullet {
    width: 5px;
    height: 5px;
    margin: 0 2px !important;
  }

  .hero-carousel .swiper-pagination-bullet-active {
    width: 6px;
    height: 6px;
  }

    .home-offers .hero-carousel {
        height: auto;
    }
}
