a {
    color: white;
    text-decoration: none;
}

.otp {
    width: 100%;
    justify-content: center;
    gap: 24px;
}

.otp > div {
    gap: 24px;
}

.otp-input {
    border: 1px double transparent;
    background-image: linear-gradient(90deg, rgb(40, 41, 55) 0%, rgb(40, 41, 55) 100.14%), linear-gradient(90deg, #EB1E2E 0%, #5043DA 100.14%);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    color: white;
    font-size: 36px;
    font-weight: 700;
    padding: 0;
    width: 100px !important;
    height: 74px;
    outline: none;
    border-radius: 20px;
}
.header-nav a {
    position: relative;
    font-weight: 400;
}

.header-nav a::after{
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: -8px;
    height: 0.1875rem;
    width: 0;
    background: linear-gradient(90deg, #6DFFE7 -10.2%, #6DFFE7 51.5%, #6DFFE7 102.62%);
    border-radius: 50px;
    transition: width .2s ease-in-out;
}

.header-nav a:hover::after,
.header-nav a.active::after {
    width: 100%;
}

html[lang=ka] .header-nav {
    gap: 16px;
}

html[lang=ka] .header-nav a {
    font-weight: 400;
    font-feature-settings: 'case' on;
}

.simplebar-scrollbar::before {
    opacity: 1 !important;
    width: 100%;
    background: linear-gradient(90deg, rgba(249, 147, 230, 0.6) -10.2%, rgba(186, 180, 255, 0.6) 102.62%);
}

.simplebar-track.simplebar-vertical {
    width: 4px;
}

.register-modal .simplebar-vertical,
.career-modal .simplebar-vertical{
    right: -30px;
}

.font-smooth {
    -webkit-font-smoothing: subpixel-antialiased;
}

.MuiPickersPopper-root .MuiPaper-root {
    border: double 1px transparent;
    background-image: linear-gradient(90deg, rgba(23, 24, 34, 1) 0%, rgba(23, 24, 34, 1) 100.14%),linear-gradient(90deg, rgba(235, 30, 46, 1) 0%, rgba(80, 67, 218, 1) 100.14%);
    background-origin: border-box;
    -webkit-background-clip: padding-box,border-box;
    background-clip: padding-box,border-box;
}

.MuiPickersPopper-root .MuiPickersDay-today {
    border: double 1px transparent;
    background-image: linear-gradient(90deg, rgba(23, 24, 34, 1) 0%, rgba(23, 24, 34, 1) 100.14%),linear-gradient(90deg, rgba(235, 30, 46, 1) 0%, rgba(80, 67, 218, 1) 100.14%);
    background-origin: border-box;
    -webkit-background-clip: padding-box,border-box;
    background-clip: padding-box,border-box;
}

.MuiPickersPopper-root .Mui-selected {
    background: linear-gradient(90deg, rgba(235, 30, 46, 1) 0%, rgba(80, 67, 218, 1) 100.14%);
}

.MuiPickersPopper-root .MuiDayPicker-weekDayLabel,
.MuiPickersPopper-root .MuiPickersDay-root,
.MuiPickersPopper-root .MuiButtonBase-root{
    color: white;
}

.MuiPickersPopper-root  .MuiPickersDay-root {
    margin-top: 4px;
    background-color: rgba(255, 255, 255, 0.1);
}

.MuiPickersPopper-root  .MuiPickersDay-root:hover {
    background-color: rgba(255, 255, 255, 0.15);
}

.stream-container {
    width: 900px;
    margin: 0 auto;
}

.stream-container iframe {
    border-radius: 16px;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

@keyframes header {
    0%   {transform: translateY(-100%)}
    100% {transform: translateY(0)}
}

@media (max-width: 1365px) {
    html[lang=ka] .header-nav {
        gap: 16px;
    }

    html[lang=ka] .header-nav a {
        font-size: 14px;
    }
}

@media (max-width: 768px) {
    .otp-input {
        width: 58px !important;
        height: 54px;
        font-size: 32px;
        border-radius: 16px;
    }

    .stream-container {
        width: 100%;
    }

    .stream-container iframe {
        height: 190px;
    }
}

.text-big {
    font-size: 24px;
}

@media (max-width: 1163px) {
    .text-big {
        font-size: 14px;
    }
}
